













































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Station } from '@/models/station.model';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';

@Component({
  name: 'StationCard',
  components: {
    StatusIcon,
  },
})
export default class StationCard extends Vue {
  @Prop() public station?: Station;
  @Prop() public simplified?: boolean;

  public zoom: number = 7;
  public url: string = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  public attribution: string = '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>';

  public getLastNote(station?: Station) {
    return station?.lastNote?.note ?? null;
  }
}
