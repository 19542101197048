



































































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { GeometryUtils } from '@/utils/geometry.utils';
import { Node } from '@/models/node.model';
import { Station } from '@/models/station.model';

@Component({
  name: 'NodesEdit',
})
export default class NodesEdit extends Vue {
  @Prop() public nodes!: Node[];
  @Prop() public station!: Station;
  @Prop({ default: false }) public disabled?: boolean;

  public innerNodes: Node[] = [];
  public openPanels: number[] = [];

  public mounted() {
    this.onModelChange();
    this.openPanels = Array.from(Array(this.innerNodes.length).keys());
    setTimeout(() => {
      this.openPanels = [0];
    }, 10);
  }

  @Watch('nodes')
  public onModelChange() {
    this.innerNodes = _.clone(this.nodes);

    setTimeout(() => {
      if (this.$refs.nodesValidateObs) {
        (this.$refs.nodesValidateObs as any).validate();
      }
    }, 500);
  }

  public addNode() {
    this.innerNodes.push(new Node(this.innerNodes.length));

    this.$emit('update:nodes', this.innerNodes);
  }

  public removeNode(index: number) {
    this.innerNodes.splice(index, 1);

    this.$emit('update:nodes', this.innerNodes);
  }

  public recalculateNodePosition(node: Node, index: number) {
    const nodeClone = _.cloneDeep(node);
    const newLocation = GeometryUtils.toAbsoluteCoords(this.station.location, node.relativeLocation);

    if (newLocation) {
      nodeClone.location = newLocation;
    }

    if (this.innerNodes.length) {
      this.$set(this.innerNodes, index, nodeClone);
    }

    this.$emit('update:nodes', this.innerNodes);
  }
}
