

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Node } from '@/models/node.model';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';

@Component({
  name: 'NodesTable',
  components: { StatusIcon },
})
export default class NodesTable extends Vue {
  @Prop({ default: () => 0 }) public nodes?: Node[];
}
