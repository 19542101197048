var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-expansion-panels',{model:{value:(_vm.openPanels),callback:function ($$v) {_vm.openPanels=$$v},expression:"openPanels"}},_vm._l((_vm.nodes),function(node,i){return _c('v-expansion-panel',{key:i,staticClass:"elevation-10"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":invalid},scopedSlots:_vm._u([(invalid)?{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-exclamation ")])]},proxy:true}:null],null,true)},[_vm._v(" Node "+_vm._s(node.nodeId)+" ")]),_c('v-expansion-panel-content',[(node.relativeLocation)?_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"X","rules":{ required: !_vm.disabled, double: !_vm.disabled }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","step":"0.001","label":"X","prepend-icon":"mdi-alpha-x-box","suffix":"km","disabled":_vm.disabled,"messages":touched || valid ? '' : 'X is required, double format',"error-messages":errors},on:{"change":function($event){return _vm.recalculateNodePosition(node, i)}},model:{value:(node.relativeLocation.x),callback:function ($$v) {_vm.$set(node.relativeLocation, "x", _vm._n($$v))},expression:"node.relativeLocation.x"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Y","rules":{ required: !_vm.disabled, double: !_vm.disabled }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","step":"0.001","label":"Y","prepend-icon":"mdi-alpha-y-box","suffix":"km","disabled":_vm.disabled,"messages":touched || valid ? '' : 'Y is required, double format',"error-messages":errors},on:{"change":function($event){return _vm.recalculateNodePosition(node, i)}},model:{value:(node.relativeLocation.y),callback:function ($$v) {_vm.$set(node.relativeLocation, "y", _vm._n($$v))},expression:"node.relativeLocation.y"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Z","rules":{ required: !_vm.disabled, double: !_vm.disabled }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"number","step":"0.001","label":"Z","prepend-icon":"mdi-alpha-z-box","suffix":"km","disabled":_vm.disabled,"messages":touched || valid ? '' : 'Z is required, double format',"error-messages":errors},on:{"change":function($event){return _vm.recalculateNodePosition(node, i)}},model:{value:(node.relativeLocation.z),callback:function ($$v) {_vm.$set(node.relativeLocation, "z", _vm._n($$v))},expression:"node.relativeLocation.z"}})]}}],null,true)}),(!_vm.disabled)?_c('v-btn',{staticClass:"mt-3",attrs:{"block":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.removeNode(i)}}},[_vm._v("Remove Node")]):_vm._e()],1)],1):_vm._e()],1)]}}],null,true)})],1)}),1),(!_vm.disabled)?_c('v-btn',{staticClass:"mt-3",attrs:{"block":"","small":"","color":"secondary"},on:{"click":_vm.addNode}},[_vm._v("Add Node")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }