































































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Action } from 'vuex-class';
import { NS_ALERTS } from '@/constants/app.constants';
import { Station } from '@/models/station.model';
import { Permissions } from '@/services/permissions.service';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';
import StationForm from '@/components/station/StationForm.component.vue';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';

@Component({
  name: 'StationSinglesListTable',
  components: { StatusIcon, StationForm, ConfirmationDialog },
})
export default class StationSinglesListTable extends Vue {
  @Prop({ default: () => [] }) public stations!: Station[];
  @Prop({ default: true }) public isFiltersOpen!: boolean;
  @Prop({ default: true }) public isLoading!: boolean;
  @Prop({ default: '' }) public selectedStationId!: string;
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public tableHeaders: DataTableHeader[] = [
    { text: 'Health', value: 'status', width: 100 },
    { text: 'ID', value: 'stationId' },
    { text: 'Delay', value: 'delay' },
    { text: 'Last updated On', value: 'lastUpdated' },
    { text: 'Last healthy On', value: 'lastHealthy' },
    { text: 'Reporter', value: 'techName' },
  ];
  public selectedStation: Station | null = null;
  public confirmMessage: string = '';
  public confirmType: string = '';
  public confirmDialogOpen: boolean = false;
  public selectedItems: any[] = [];

  public mounted() {
    if (!Permissions.isAdmin()) {
      this.tableHeaders = _(this.tableHeaders)
        .reject((header) => header.value === 'customerId')
        .value();
    }
  }

  public onChartMouseOver(stationId: string) {
    this.$emit('hover', stationId);
  }

  public onChartMouseLeave() {
    this.$emit('hover', null);
  }

  @Watch('stations')
  public onStationsChange() {
    this.selectedItems = [];
  }

  @Watch('selectedItems')
  public onSelectedItemsChange() {
    this.$emit('selected', this.selectedItems);
  }
}
