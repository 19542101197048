



















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';
import { StationHealth } from '@/models/station.model';

@Component({
  name: 'NodesTable',
  components: { StatusIcon },
})
export default class StationInfoTable extends Vue {
  @Prop({ default: () => 0 }) public station?: StationHealth;
}
