





































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Station, StationHealth } from '@/models/station.model';
import GaugeChart from '@/components/charts/GaugeChart.component.vue';
import NodesTable from '@/components/shared/NodesTable.component.ts.vue';
import StationCard from '@/components/station/StationCard.component.vue';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';
import StationInfoTable from '@/components/station/StationInfoTable.component.vue';
import StationLoadChart from '@/components/station/StationLoadChart.component.vue';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import StationNotesTable from '@/components/shared/StationNotesTable.component.ts.vue';
import StationService from '@/services/station.service';

const namespace: string = 'stations';

@Component({
  name: 'StationDetails',
  components: {
    LoadingBar,
    StationsSelectTree,
    StationCard,
    NodesTable,
    GaugeChart,
    StatusIcon,
    StationInfoTable,
    StationLoadChart,
    StationNotesTable,
  },
})
export default class StationDetails extends Vue {
  @Prop() public stationId?: string;
  @Getter('getStationHealth', { namespace }) getStationHealth?: StationHealth;
  @Getter('getStation', { namespace }) station?: Station;
  @Getter('getStationHealthError', { namespace }) stationHasError?: boolean;
  @Action('fetchStationHealth', { namespace }) public fetchStationHealth: any;
  @Action('fetchStation', { namespace }) public fetchStation: any;

  public snackbar: boolean = false;
  public timerId: any;
  public isActive: boolean = true;

  public mounted() {
    this.fetchStationHealth({ stationId: this.stationId });
    this.fetchStation({ stationId: this.stationId });

    this.timerId = setInterval(() => {
      this.fetchStationHealth({ stationId: this.stationId });
      this.fetchStation({ stationId: this.stationId });
    }, 4000);
  }

  public destroyed() {
    clearInterval(this.timerId);
  }

  @Watch('stationHasError')
  public onStationError() {
    this.snackbar = true;
    setTimeout(() => {
      this.$router.replace('/station-monitor');
    }, 3000);
  }

  public onActiveChange() {
    if (this.station) {
      StationService.saveActiveState(this.station.stationId, this.station.isActive).catch(() => {
        if (this.station) {
          this.station.isActive = !this.station?.isActive;
        }
      });
    }
  }
}
