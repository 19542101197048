var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-10",class:_vm.isFiltersOpen ? 'station-list-table' : 'station-list-table--tight',attrs:{"headers":_vm.tableHeaders,"items":_vm.stations,"items-per-page":-1,"item-key":"stationId","loading":_vm.isLoading,"sort-desc":true,"fixed-header":"","show-select":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('StatusIcon',{attrs:{"status":item.status},on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}})]}},{key:"item.stationId",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },attrs:{"id":("station-table-" + (item.stationId))},on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(_vm._s(item.stationId))])]}},{key:"item.delay",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(_vm._s(item.delay))])]}},{key:"item.lastUpdated",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(_vm._s(_vm._f("getDateTime")(item.lastUpdated)))])]}},{key:"item.lastHealthy",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(_vm._s(_vm._f("getDateTime")(item.lastHealthy)))])]}},{key:"item.techName",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ selected: item.stationId === _vm.selectedStationId },on:{"mouseover":function($event){return _vm.onChartMouseOver(item.stationId)},"mouseleave":_vm.onChartMouseLeave}},[_vm._v(_vm._s(item.techName))])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }