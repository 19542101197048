





































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Options } from '@/models/app.model';
import { SENSOR_PROVISIONING_CHANNELS_OPTIONS, SENSOR_PROVISIONING_OPTIONS } from '@/constants/filters.constants';

@Component({
  name: 'MainChannelSelect',
})
export default class MainChannelSelect extends Vue {
  @Prop() public sensorProvisioning!: string;
  @Prop() public mainChannelId!: number;
  @Prop({ default: false }) public disabled?: boolean;

  public innerMainChannelId: number | null = null;
  public innerSensorProvisioning: string = '';
  public provisioningTypes: Options<string>[] = SENSOR_PROVISIONING_OPTIONS;
  public channels: Options<number>[] = [];

  public mounted() {
    this.onMainChannelIdChange();
    this.onSensorProvisioningChange();
  }

  @Watch('mainChannelId')
  public onMainChannelIdChange() {
    this.innerMainChannelId = _.clone(this.mainChannelId);
  }

  @Watch('sensorProvisioning')
  public onSensorProvisioningChange() {
    this.innerSensorProvisioning = _.clone(this.sensorProvisioning);
  }

  @Watch('innerSensorProvisioning')
  public onInnerSensorProvisioningChange() {
    const allChannelIds = _(SENSOR_PROVISIONING_CHANNELS_OPTIONS).find({ name: this.innerSensorProvisioning });
    this.channels = allChannelIds ? allChannelIds.value.map((value) => ({ name: `${value}`, value })) : [];
    // this.innerMainChannelId = null;
  }
}
