























import { Component, Vue } from 'vue-property-decorator';
import { Station, StationSingleHealthType } from '@/models/station.model';
import StationCard from '@/components/station/StationCard.component.vue';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';
import StationService from '@/services/station.service';
import StationSinglesListTable from '@/components/station/StationSinglesListTable.component.vue';
import { FiltersStationSinglesHealth } from '@/models/states/filters-state.model';
import OptionsStationSinglesHealth from '@/components/options-panels/OptionsStationSinglesHealth.component.vue';

const namespace: string = 'stations';

@Component({
  name: 'StationSingleHealth',
  components: {
    OptionsStationSinglesHealth,
    StationsSelectTree,
    StationSinglesListTable,
    RealTimeMap,
    StationCard,
    StatusIcon,
  },
})
export default class StationSingleHealth extends Vue {
  public stations: StationSingleHealthType[] = [];
  public loading: boolean = false;
  public isFiltersOpen: boolean = true;
  public currentFilters: FiltersStationSinglesHealth = {
    customers: ['ak'],
    status: [],
  };
  public selectedStations: string[] = [];

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  public onFiltersChange(filters: FiltersStationSinglesHealth) {
    this.currentFilters = filters;
    this.getStations(filters);
  }

  public getStations(filters: FiltersStationSinglesHealth) {
    this.loading = true;
    StationService.querySinglesHealth(filters.customers)
      .then((response) => {
        this.loading = false;
        this.stations = response.filter((station) => {
          return filters.status.length ? filters.status.includes(station.status) : true;
        });
      })
      .catch(() => {
        this.loading = false;
      });
  }

  public onStationsSelected(stations: Station[]) {
    this.selectedStations = stations.map((station) => station.stationId);
  }
}
