
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { StationNote } from '@/models/station.model';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import StationService from '@/services/station.service';

@Component({
  name: 'StationNotesTable',
  components: { LoadingBar },
})
export default class StationNotesTable extends Vue {
  @Prop() public stationId?: string;

  public notesDialog: boolean = false;
  public newStationNote: string = '';
  public saveNoteLoading: boolean = false;
  public notes: StationNote[] = [];

  public mounted() {
    this.loadNotes();
  }

  public get hasData(): boolean {
    return !!this.notes?.length;
  }

  public saveNote() {
    if (!this.stationId) {
      return;
    }

    this.saveNoteLoading = true;
    StationService.saveNote(this.stationId, this.newStationNote).then(
      () => {
        this.notesDialog = false;
        this.saveNoteLoading = false;
        this.loadNotes();
      },
      () => {
        this.saveNoteLoading = false;
      },
    );
  }

  public loadNotes() {
    if (!this.stationId) {
      return;
    }

    StationService.queryNotes({ stations: [this.stationId] }).then((response) => {
      this.notes = response;
    });
  }

  public cancelSaveNote() {
    this.notesDialog = false;
    this.newStationNote = '';
  }
}
