









import _ from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { HighChartsGaugeOptions } from '@/constants/highcharts-options.constant';

@Component({
  name: 'GaugeChart',
})
export default class GaugeChart extends Vue {
  @Prop({ default: () => 0 }) public max?: number;
  @Prop({ default: () => 0 }) public min?: number;
  @Prop({ default: () => 0 }) public value?: number;
  @Prop({ default: () => '' }) public title?: string;
  @Prop({ default: () => '' }) public unit?: string;

  public chartOptions: Partial<Highcharts.Options> = {};

  public mounted() {
    this.chartOptions = _.extend({}, HighChartsGaugeOptions, {
      yAxis: {
        min: this.min,
        max: this.max,
        title: {
          text: '',
        },
      },

      credits: {
        enabled: false,
      },

      series: [
        {
          name: '',
          data: [this.value],
          dataLabels: {
            format: `<div style="text-align:center"><span style="font-size:25px">{y}</span><span style="font-size:12px;opacity:0.4">${this.unit}</span></div>`,
          },
          tooltip: {
            valueSuffix: ` ${this.unit}`,
          },
        },
      ],
    });
  }
}
