





















































































































































































import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { NS_ALERTS, NS_STATIONS } from '@/constants/app.constants';
import { getDateTime, getTime } from '@/filters/time.filter';
import { Station } from '@/models/station.model';
import StationService from '@/services/station.service';
import CustomerSelect from '@/components/shared/CustomerSelect.component.vue';
import DateTimeSelector from '@/components/shared/DateTimeSelector.component.vue';
import MainChannelSelect from '@/components/shared/MainChannelSelect.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import NodesEdit from '@/components/shared/NodesEdit.component.vue';
import { Permissions } from '@/services/permissions.service';

@Component({
  name: 'StationForm',
  components: { CustomerSelect, DateTimeSelector, LoadingBar, MainChannelSelect, NodesEdit, RealTimeMap },
})
export default class StationForm extends Vue {
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;
  @Action('updateStation', { namespace: NS_STATIONS }) public updateStation: any;

  @Prop() public station!: Station;
  @Prop({ default: () => true }) public isEdit!: boolean;

  public innerStation: Station | null = null;
  public isLoading: boolean = false;
  public isAdmin: boolean = false;
  public isStationConfigured: boolean = true;

  public mounted() {
    this.onStationChange();
    this.isAdmin = Permissions.isAdmin();
  }

  @Watch('station')
  public onStationChange() {
    this.isStationConfigured = this.isEdit ? this.isEdit && !!this.station.location && !!this.station.stationName : true;

    if (this.isStationConfigured) {
      this.innerStation = _.extend({}, this.station, {
        parsedUpdatedAtDate: getDateTime(this.station.updatedAt, 'YYYY-MM-DD'),
        parsedUpdatedAtTime: getTime(this.station.updatedAt),
        parsedDeployedOnDate: this.station.deployedOn ? getDateTime(this.station.deployedOn, 'YYYY-MM-DD') : '',
        parsedDeployedOnTime: this.station.deployedOn ? getTime(this.station.deployedOn) : '',
      });
    }
  }

  public save() {
    this.isLoading = true;

    if (this.innerStation?.parsedDeployedOnDate && this.innerStation?.parsedDeployedOnTime) {
      this.innerStation.deployedOn = moment(`${this.innerStation?.parsedDeployedOnDate} ${this.innerStation?.parsedDeployedOnTime}`).valueOf();
    }

    StationService.save(_.pick(this.innerStation, ['stationId', 'deployedOn', 'customerId'])).then(
      (station) => {
        this.isLoading = false;
        this.updateStation({
          station,
        });
        this.resetFormValidation();
        this.addAlert({
          type: 'success',
          header: 'Station was created.',
          message: 'Please add the station configuration from Saber.',
          timeout: 5000,
        });
        this.$router.push({ name: 'stations' });
      },
      (errorEvent: ErrorEvent) => {
        this.isLoading = false;
        this.addAlert({
          type: 'error',
          header: 'There was a problem saving station',
          message: _.get(errorEvent, 'response.data'),
          timeout: 5000,
        });
      },
    );
  }

  private resetFormValidation() {
    if (this.$refs.observer) {
      (this.$refs.observer as any).reset();
    }
  }
}
