var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Provisioning Type","rules":{ required: !_vm.disabled }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.provisioningTypes,"item-value":"value","item-text":"name","label":"Provisioning Type","prepend-icon":"mdi-hexagon-outline","disabled":_vm.disabled,"messages":touched || valid ? '' : 'Provisioning Type is required',"error-messages":errors},on:{"change":function($event){return _vm.$emit('update:sensorProvisioning', _vm.innerSensorProvisioning)}},model:{value:(_vm.innerSensorProvisioning),callback:function ($$v) {_vm.innerSensorProvisioning=$$v},expression:"innerSensorProvisioning"}})]}}])})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Main Channel Id","rules":{ required: !_vm.disabled }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.channels,"item-value":"value","item-text":"name","disabled":!_vm.innerSensorProvisioning || _vm.disabled,"label":"Main Channel Id","prepend-icon":"mdi-hexagon-outline","messages":touched || valid ? '' : 'Main Channel Id is required',"error-messages":errors},on:{"change":function($event){return _vm.$emit('update:mainChannelId', _vm.innerMainChannelId)}},model:{value:(_vm.innerMainChannelId),callback:function ($$v) {_vm.innerMainChannelId=$$v},expression:"innerMainChannelId"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }