



































import _, { cloneDeep } from 'lodash';
import { DataOptions } from 'vuetify';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { NS_ALERTS } from '@/constants/app.constants';
import { FiltersStationSinglesHealth } from '@/models/states/filters-state.model';
import { QueryService } from '@/services/query.service';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';
import SimpleSelect from '@/components/shared/SimpleSelect.component.vue';
import CustomerSelect from '@/components/shared/CustomerSelect.component.vue';
import ConfirmationDialog from '@/components/shared/ConfirmationDialog.component.vue';
import StationService from '@/services/station.service';
import { Options } from '@/models/app.model';
import { STATION_STATUSES } from '@/constants/station-configuration.constants';

@Component({
  name: 'OptionsStationSinglesHealth',
  components: {
    ConfirmationDialog,
    CustomerSelect,
    StationsSelectTree,
    SimpleSelect,
  },
})
export default class OptionsStationSinglesHealth extends Vue {
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;
  @Prop() public tableOptions?: Partial<DataOptions>;
  @Prop({ default: true }) public isOpen!: number;
  @Prop({ default: false }) public showServices?: boolean;
  @Prop({ default: () => [] }) public selectedStations!: string[];
  public dialogOpen: boolean = false;
  public statuses: Options<string>[] = cloneDeep(STATION_STATUSES);

  public filters: Partial<FiltersStationSinglesHealth> = {
    customers: [],
    status: [],
  };

  public isOpenInner: number | undefined = 0;

  public mounted() {
    this.applyUrlParams();
  }

  @Emit()
  public onFiltersChanged(): Partial<FiltersStationSinglesHealth> {
    return this.filters;
  }

  @Watch('isOpen')
  public onOpenChange() {
    this.isOpenInner = this.isOpen ? 0 : undefined;
  }

  @Watch('$route')
  public onRouteChange() {
    this.applyUrlParams();
  }

  public applyFilters() {
    this.onFiltersChanged();
    this.updateQueryParams();
  }

  public updateQueryParams() {
    const newQueryParams = _.omit(this.filters, ['date', 'time']);
    const queryParams = _.omit(this.getQueryParams(), ['date', 'time']);

    if (_.isEqual(newQueryParams, queryParams)) {
      return;
    }

    this.$router
      .push(
        _.extend({}, this.$route, {
          query: QueryService.convertQueryParamsToString(newQueryParams),
        }),
      )
      .catch(() => {});
  }

  public report() {
    this.dialogOpen = true;
  }

  public onReport() {
    StationService.notifyReporter(this.selectedStations)
      .then(() => {
        this.dialogOpen = false;
        this.addAlert({
          message: 'The reporter was notified successfully',
          type: 'success',
        });
      })
      .catch(() => {
        this.dialogOpen = false;
        this.addAlert({
          message: 'Failed to notify reporter',
          type: 'error',
        });
      });
  }

  public onReportCancel() {
    this.dialogOpen = false;
  }

  private applyUrlParams() {
    if (!_.isEmpty(this.$route.query)) {
      this.filters = this.getQueryParams();
    }

    this.applyFilters();
  }

  private getQueryParams(): Partial<FiltersStationSinglesHealth> {
    if (!_.isEmpty(this.$route.query)) {
      return {
        customers: QueryService.getCustomers(this.$route) || [],
        status: QueryService.getOptions(this.$route, 'status') || [],
      };
    }
    return {};
  }
}
