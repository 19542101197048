import { Channel } from '@/models/channel.model';
import { RelativePosition, SeismicPosition } from '@/models/position.model';
import { StationHealthStatus } from '@/constants/station.constants';

export interface NodeBasic {
  channels: Channel[];
}

export class Node {
  public healthStatus: StationHealthStatus = StationHealthStatus.HEALTHY;
  public relativeLocation: RelativePosition = { x: 0, y: 0, z: 0 };
  public location?: SeismicPosition = { lat: 0, long: 0, alt: 0 };
  public nodeId: number = -1;
  public lastCallHomeTime?: number = -1;

  constructor(nodeId: number) {
    this.nodeId = nodeId;
  }
}

export interface NodeReading {
  nodeId: number;
  nodeStatus: string;
  data: number[][];
}
