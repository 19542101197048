












import _ from 'lodash';
import moment from 'moment-timezone';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GREEN_COLOR, ORANGE_COLOR, RED_COLOR } from '@/constants/app.constants';
import StationService from '@/services/station.service';
import { Station, StationHealth } from '@/models/station.model';
import { StationHealthQueryResponse } from '@/models/response.model';

@Component({
  name: 'StationLoadChart',
})
export default class StationLoadChart extends Vue {
  @Prop() public station?: Station;

  public hasData: boolean = false;
  public isLoading: boolean = true;
  public chartOptions: any = {};
  public loadedData: {
    status: StationHealth;
    time: number;
  }[] = [];

  public mounted() {
    if (this.station) {
      StationService.queryHealth(this.station.stationId, {
        from: moment().subtract(1, 'day').valueOf(),
        timeGrouping: { unit: 'MINUTES', value: 10 },
      }).then(
        (data: StationHealthQueryResponse) => this.onLoaded(data),
        () => this.onError(),
      );
    }

    const innerGetFullStationHealth = this.getFullStationHealth;
    const formatter = function formatter() {
      let status: string = 'WARNING';
      // @ts-ignore
      if (this.point.value === 0) {
        status = 'Unhealthy';
      }
      // @ts-ignore
      if (this.point.value === 100) {
        status = 'Healthy';
      }
      // @ts-ignore
      if (this.point.value === null) {
        status = 'No Data';
      }
      // @ts-ignore
      const stationHealth = innerGetFullStationHealth(this.point.x);
      // @ts-ignore
      let response = `<b>${this.series.xAxis.categories[this.point.x]}: ${status}</b>`;
      if (stationHealth) {
        // @ts-ignore
        response += `<br/>
              <span>Nodes status: ${stationHealth.nodes.status}</span><br/>
              <span>MQTT status: ${stationHealth.mqtt.status}</span><br/>
              <span>MSeeds status: ${stationHealth.mseeds.status}</span><br/>
              <div>Resource status: ${stationHealth.resources.status}<br/>
                <ul>
                  <li><span>CPU: ${stationHealth.resources.cpuLoad}%</span><br/></li>
                  <li><span>Disk: ${stationHealth.resources.diskFreeSpaceGB} / ${stationHealth.resources.diskTotalSpaceGB} GB</span><br/></li>
                  <li><span>Memory: ${stationHealth.resources.memoryCommittedGB} GB / ${stationHealth.resources.memoryMaxHeap} GB</span><br/></li>
                </ul>
              </div>


              `;
      }
      return response;
    };

    this.chartOptions = {
      chart: {
        height: 200,
        type: 'heatmap',
        marginTop: 10,
        marginBottom: 100,
        plotBorderWidth: 1,
      },

      title: {
        text: '',
      },

      xAxis: {
        categories: [],
      },

      yAxis: {
        categories: ['Health'],
        title: null,
        reversed: false,
      },

      colorAxis: {
        min: 0,
        dataClasses: [
          {
            color: ORANGE_COLOR,
            from: 49,
            to: 51,
          },
          {
            color: RED_COLOR,
            from: 0,
            to: 49,
          },
          {
            color: GREEN_COLOR,
            from: 51,
            to: 100,
          },
        ],
        minColor: RED_COLOR,
        maxColor: GREEN_COLOR,
      },

      legend: {
        enabled: false,
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 25,
        symbolHeight: 280,
      },

      tooltip: {
        formatter,
      },

      series: [
        {
          name: 'Load',
          borderWidth: 2,
          data: [],
          dataLabels: {
            enabled: false,
            color: '#000000',
          },
        },
      ],
    };
  }

  private onLoaded(data: StationHealthQueryResponse) {
    this.hasData = !!data.statuses.length;
    this.isLoading = false;

    if (!this.hasData) {
      return;
    }

    this.loadedData = data.statuses;

    const parsed = data.statuses.map((status: any) => ({
      time: status.time,
      value: _.get(status, 'status.status', null),
    }));

    this.chartOptions.xAxis.categories = parsed.map((item: any) => moment.unix(item.time / 1000).format('MM-DD HH:mm:ss'));
    this.chartOptions.series[0].data = parsed.map((item: any, index: number) => {
      const serverValue = item.value;
      let value: any = 50;
      if (serverValue === 'UNHEALTHY') {
        value = 0;
      }
      if (serverValue === 'HEALTHY') {
        value = 100;
      }
      if (serverValue === null) {
        value = null;
      }
      return [index, 0, value];
    });
  }

  private onError() {
    this.isLoading = false;
  }

  private getFullStationHealth(index: number): StationHealth {
    return this.loadedData[index].status;
  }
}
