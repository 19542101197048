





















































import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Station } from '@/models/station.model';
import StationCard from '@/components/station/StationCard.component.vue';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';

const namespace: string = 'stations';

@Component({
  name: 'StationMonitor',
  components: {
    StationsSelectTree,
    RealTimeMap,
    StationCard,
    StatusIcon,
  },
})
export default class StationMonitor extends Vue {
  @Getter('getAllStations', { namespace }) stations?: Station[];
  @Action('fetchStations', { namespace }) public fetchStations: any;
  public selectedStationId: string | null = null;
  public statusFilter: string = '';
  public stationIdFilter: string[] = [];
  public disabledFilter: boolean = false;

  public mounted() {
    this.fetchStations();
  }

  public get groupedStations(): { [key: string]: Station[] } {
    return _(this.filteredStations).groupBy('healthStatus').value();
  }

  public get filteredStations(): Station[] {
    const stations = this.stations ?? [];

    return stations
      .filter((station) => {
        const healthCondition = this.statusFilter ? station.healthStatus === this.statusFilter : true;
        const idCondition = this.stationIdFilter.length ? this.stationIdFilter.includes(station.stationId) : true;

        return healthCondition && idCondition;
      })
      .filter((station) => {
        return this.disabledFilter ? !station.isActive : station.isActive;
      });
  }

  public get allGroupedStations(): { [key: string]: Station[] } {
    const stations = this.stations ?? [];
    return _(stations)
      .filter((station) => {
        return this.stationIdFilter.length ? this.stationIdFilter.includes(station.stationId) : true;
      })
      .filter((station) => {
        return this.disabledFilter ? !station.isActive : station.isActive;
      })
      .groupBy('healthStatus')
      .value();
  }

  public filterStations(status: string) {
    this.statusFilter = status;
  }

  public scrollToStation(stationId: string) {
    const scrollPosition: number = document.getElementById(`station-card-${stationId}`)?.getBoundingClientRect().top ?? 0;
    document.getElementsByClassName('station-health-fill__height')[0].scrollTo(0, scrollPosition - 200);

    this.selectedStationId = stationId;
  }
}
